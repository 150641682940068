import Vue from 'vue'
import Router from 'vue-router'
import Chat from '@/views/chat/index.vue';
import Login from '@/views/login/index.vue';
import Manage from '@/views/chat/manage.vue'
Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/chat',
      name: 'chat',
      component: Chat
    },
    {
      path: '/manage',
      name: 'manage',
      component: Manage
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
  } else {
    let token = sessionStorage.getItem('token');
    let userInfo = sessionStorage.getItem('userInfo');
    if (token) {
      next()
    } else {
      next('/login')
    }

    if (userInfo && JSON.parse(userInfo).userRole == 1){
      next('/manage')
    } else if (userInfo && JSON.parse(userInfo).userRole == 2){
      next('/chat')
    }
  }
})
export default router