//main server
// const DEFAULT_URL = 'http://1.12.236.210/zlh-mall-test002'

//img server
const DEFAULT_IMG_URL = 'http://1.12.236.210/zlh-mall-byj-img'

//客服server
const DEFAULT_CHAT_URL = 'http://1.12.236.210/zlh-byj-chat'

// websocket 客服聊天
const DEFAULT_CUSTOMER_URL = 'http://1.12.236.210:7070';
// const DEFAULT_CUSTOMER_URL = 'http://8.129.223.241:7070';

// socket 客服聊天
const DEFAULT_SOCKET_URL = 'ws://1.12.236.210:7070/websocket';
// const DEFAULT_SOCKET_URL = 'ws://8.129.223.241:7070/websocket';

export const urlConfig = {
	// BASE_URL: DEFAULT_URL,
	IMG_URL: DEFAULT_IMG_URL,
	CHAT_URL: DEFAULT_CHAT_URL,
	SOCKET_URL: DEFAULT_SOCKET_URL,
	CUSTOMER_URL: DEFAULT_CUSTOMER_URL,
}