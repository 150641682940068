<template>
  <div class="login-container">
    <div class="login-box">
      <div class="header">
        <span>欢迎登录客服系统</span>
      </div>
      <div class="login-body">
        <el-form :model="loginForm" :rules="rules" ref="loginForm">
          <el-form-item prop="username">
            <el-input prefix-icon="el-icon-user" v-model="loginForm.username" placeholder="请输入用户名或手机号"></el-input>
          </el-form-item>
          <el-form-item prop="password" class="captcha-item">
            <el-input 
              prefix-icon="el-icon-key" 
              type="password" 
              v-model="loginForm.password" 
              placeholder="请输入密码" 
              @keyup.enter.native="submitForm('loginForm')"></el-input>
            <!-- <el-link :underline="false" :disabled="captchaDisabled" type="primary" @click="getCaptcha">{{ captchaTxt }}</el-link> -->
          </el-form-item>
          <el-form-item>
            <el-button class="block" type="primary" @click="submitForm('loginForm')">登录</el-button>
          </el-form-item>
          <el-form-item>
            <el-button class="block" type="default" @click="resetForm('loginForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
const Base64 = require("js-base64").Base64;
const CryptoJS = require("crypto-js");
import { login, getCode } from "@/http/chat"
import { constant } from '@/utils/utils';
export default {
  data() {
    return {
      captchaDisabled: false,
      captchaTxt: '获取验证码',
      Countdown: 60,
      loginForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名或手机号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.loginForm.username, this.loginForm.password);
          let params = {
            mobileNum: Base64.encode(this.loginForm.username),
            pwd: Base64.encode(this.loginForm.password)
          }

          login(params).then(res => {
            console.log(res);
            // 处理登录成功后的逻辑
            if (res.status === 200) {
              sessionStorage.setItem('userInfo', JSON.stringify(res.data));
              sessionStorage.setItem('token', JSON.stringify(res.data.token));
              if (res.data.userRole === 1){
                this.$router.push({ name: 'manage' });
              } else if (res.data.userRole === 2){
                this.$router.push({ name: 'chat' });
              }
            } else {
              this.$message.error(res.message);
            }
          }).catch(error => {
            console.error(error);
            // 处理登录失败后的逻辑
          });
        } else {
          console.log('登录失败!');
          return false;
        }
      });
    },
    getCaptcha() {
      this.captchaDisabled = true;
      this.Countdown = 59;
      this.captchaTxt = `59 秒后重试`;

      getCode(constant.WX_APPID, this.loginForm.username).then(res => {
        console.log(res);
        if (res.status === 200) {
          this.$message.success('验证码发送成功，请注意查收');
        } else {
          this.$message.error(res.message);
        }
      }).catch(error => {
        console.log(error);
      });

      let timer = setInterval(() => {
        this.Countdown--;
        if (this.Countdown === 0) {
          clearInterval(timer);
          this.captchaTxt = '获取验证码';
          this.captchaDisabled = false;
        } else {
          this.captchaTxt = `${this.Countdown} 秒后重试`;
        }
      }, 1000);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style lang="less" scoped>
.login-container {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  position: relative;
  height: 100vh;
  background: url('https://www.qghl.net/images/wp1_1.jpg') no-repeat center center fixed;
  background-size: cover;
}

.login-box {
  width: 500px;
  padding: 90px 70px;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.75);
  height: 100%;
  box-sizing: border-box;
  .header {
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    color: #333;
    margin-bottom: 80px;
  }
}

.captcha-item {
  position: relative;
  .el-link {
    position: absolute;
    right: 18px;
  }
}

.block {
  width: 100%;
}

</style>
