// src/api/request.js
import axios from 'axios';

// 创建一个 Axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // 设置 API 的基础 URL
  timeout: 1000 * 60 // 设置请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在发送请求之前可以做一些处理，例如添加 token
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    // 请求错误处理
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    // 对响应数据进行处理
    return response.data;
  },
  error => {
    // 响应错误处理
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 处理 401 错误
          break;
        case 404:
          // 处理 404 错误
          break;
        // 其他错误处理
      }
    }
    return Promise.reject(error);
  }
);

export default service;
