// src/api/chat
import request from './request';
import { urlConfig } from './urlconfig';
import qs from 'qs';

// 客服人员登陆接口-pc端
export function login(data) {
  return request({
    url: urlConfig.CHAT_URL + '/userLogin/login',
    method: 'post',
    data: qs.stringify(data)
  });
}

// 获取验证码接口
export function getCode(APPID, phoneNum) {
  return request({
    url: urlConfig.BASE_URL + `/customerVerifyCode/send/${APPID}/${phoneNum}`,
    method: 'GET',
  });
}

// 管理员查看客服聊天记录的接口-pc端  
export function queryAdviserUser(params) {
  return request({
    url: urlConfig.CHAT_URL + '/admin/queryAdviserUser',
    method: 'POST',
    params
  });
}

// /chats/list
/*
 *chatId=1&
 userNum=1&
 userHead=&
 userName=&
 userCount=1&
 sysUserNum=1&
 sysUserHead=&
 sysUserName=&
 sysUserCount=1&
 createTime=2024-07-21 16:21:14&
 chatStatus=1
 */
// 查询会话列表
export function queryChatList(params) {
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/list`,
    method: 'GET',
    params
  });
}

// 查询聊天记录
// /chatContent/list?chatContentId=1&chatId=1&content=&createTime=2024-07-24 15:39:10&status=1&type=&sendUserNum=1&overFlag=1
export function queryChatRecord(params) {
  return request({
    url: urlConfig.CUSTOMER_URL + `/chatContent/list`,
    method: 'GET',
    params
  });
}

// 查询当前会话
export function queryCurrentChat(params) {
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/cxdqhh`,
    method: 'GET',
    params
  });
}

// 查询客服
export function queryChat4Time(params) {
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/list4Time`,
    method: 'GET',
    params
  });
}
// 查询会话数量
// /chats/hhsz  sysUserNum: this.sysUserInfo.userNum
// /chats/hhsz2
export function queryChatCount(params) {
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/hhsz`,
    method: 'GET',
    params
  });
}

export function querytoBeAcceptCount(params) {
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/hhsz2`,
    method: 'GET',
    params
  });
}

// 客服发起接待聊天
export function servicerReception(data) {
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/jiedai`,
    method: 'POST',
    data: data
  });
}

// 已读消息
export function chatsRead(data) {
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/qbyd`,
    method: 'POST',
    data: data
  });
}

// 某客服对某用户提示断开
export function chatsBreak(data) {
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/tsdk`,
    method: 'POST',
    data: data
  });
}

// 客服设置某会话结束
export function chatsOver(data){
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/hhjs`,
    method: 'POST',
    data: data
  });
}


// 会话中
export function chatsSession(params){
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/list4Jxz`,
    method: 'GET',
    params: params
  });
}

// 待接受
export function chatsWaitAccept(params){
  return request({
    url: urlConfig.CUSTOMER_URL + `/chats/list4Djs`,
    method: 'GET',
    params: params
  });
}